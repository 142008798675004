// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campaign-charity-risk-assessment-template-js": () => import("./../../../src/pages/campaign/charity-risk-assessment-template.js" /* webpackChunkName: "component---src-pages-campaign-charity-risk-assessment-template-js" */),
  "component---src-pages-campaign-risk-assessment-template-js": () => import("./../../../src/pages/campaign/risk-assessment-template.js" /* webpackChunkName: "component---src-pages-campaign-risk-assessment-template-js" */),
  "component---src-pages-campaign-risk-management-software-js": () => import("./../../../src/pages/campaign/risk-management-software.js" /* webpackChunkName: "component---src-pages-campaign-risk-management-software-js" */),
  "component---src-pages-campaign-risk-register-template-js": () => import("./../../../src/pages/campaign/risk-register-template.js" /* webpackChunkName: "component---src-pages-campaign-risk-register-template-js" */),
  "component---src-templates-assetmanager-js": () => import("./../../../src/templates/assetmanager.js" /* webpackChunkName: "component---src-templates-assetmanager-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-internal-controls-js": () => import("./../../../src/templates/internal-controls.js" /* webpackChunkName: "component---src-templates-internal-controls-js" */),
  "component---src-templates-iso-14001-js": () => import("./../../../src/templates/iso14001.js" /* webpackChunkName: "component---src-templates-iso-14001-js" */),
  "component---src-templates-iso-22301-js": () => import("./../../../src/templates/iso22301.js" /* webpackChunkName: "component---src-templates-iso-22301-js" */),
  "component---src-templates-iso-27001-js": () => import("./../../../src/templates/iso27001.js" /* webpackChunkName: "component---src-templates-iso-27001-js" */),
  "component---src-templates-iso-45001-js": () => import("./../../../src/templates/iso45001.js" /* webpackChunkName: "component---src-templates-iso-45001-js" */),
  "component---src-templates-iso-9001-js": () => import("./../../../src/templates/iso9001.js" /* webpackChunkName: "component---src-templates-iso-9001-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-risk-manager-js": () => import("./../../../src/templates/risk-manager.js" /* webpackChunkName: "component---src-templates-risk-manager-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/Post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-trust-js": () => import("./../../../src/templates/trust.js" /* webpackChunkName: "component---src-templates-trust-js" */),
  "component---src-templates-wp-js": () => import("./../../../src/templates/wp.js" /* webpackChunkName: "component---src-templates-wp-js" */)
}

